import axios, { AxiosInstance } from "axios"
import { ref } from "vue"
import store from "../store/index"
import {
	CartItemWithAssemblies,
	CartItemWithVersion,
	CartItemWithoutVersion,
	CartItem,
} from "@/types"
import { useCartOrders, CartOrder } from "@/services/useCartOrders"

const apiCart: AxiosInstance = axios.create({
	baseURL: process.env.VUE_APP_API_HOST_CART,
})

apiCart.interceptors.request.use((config: any) => {
	const token = store.getters["auth/getToken"]
	const warehouseId = store.getters["auth/getUser"].warehouseId
	const userId = store.getters["auth/getUserId"]
	const customerConfiguredBySeller = store.getters["auth/getCustomerConfiguredBySeller"]
	const reference = store.getters['getReference']

	config.headers.common["token"] = token
	config.params = {
		...config.params,
		warehouse: warehouseId,
		reference: reference,
		userId: customerConfiguredBySeller
			? customerConfiguredBySeller._id
			: userId,
	}
	return config
})

export interface DataProductUpdateI {
	code: string
	quantity: number
	advancedPurchasing: boolean
	versionCode?: string
	assemblyVersionCode?: string
	assemblyCode?: string
}

export interface DataProductDeleteI {
	code: string
	advancedPurchasing: boolean
	version?: string
}

export const productsCountCart: any = ref(0)
export const pendingNews: any = ref([])
export const cart: any = ref(null)
export const isLoading = ref(true)
export const outOfStock = ref<Array<any>>([])
export const productsNews = ref<Array<any>>([])

export function useCart() {
	const cart_orders = ref<CartOrder[]>([])
	const orderSelected = ref("")
	const allProducts = ref<Array<CartItem>>()

	const addProductCart = async (
		item:
			| CartItemWithoutVersion
			| CartItemWithVersion
			| CartItemWithAssemblies
	) => {
		try {
			const response = await apiCart.post("/cart/additem", item)
			productsCountCart.value = response.data.cart.amount
			getCart() // No blocking
			return {
				status: response.status,
				message: response.data,
			}
		} catch (error: any) {
			return {
				status: error.status,
				message: error.response.data.errors[0],
			}
		}
	}

	const getCart = async () => {
		const path = `/cart/listcart`
		const response: any = await apiCart.get(path)

		if (response.status === 200) {
			cart.value = response.data.cart
			outOfStock.value = response.data.outOfStock || []
			productsNews.value = response.data.productsNews || []
			productsCountCart.value = cart.value.amount
			allProducts.value = cart.value.products || []
			store.commit("cart/setCartProducts", cart.value.products || [])
		}
	}

	const updateCart = async () => {
		const path = `/cart/listcart`
		try {
			const response: any = await apiCart.put(path)
			if (response.status === 200) {
				return true
			}
		} catch (error) {
			return false
		}
	}

	const deleteCart = async () => {
		const path = `/cart/listcart`
		try {
			const response: any = await apiCart.delete(path)
			if (response.status === 200) {
				return true
			}
		} catch (error) {
			return false
		}
	}

	const clearCart = async (): Promise<boolean> => {
		const path = `/cart/cleanCart`
		try {
			const response: any = await apiCart.delete(path)
			if (response.status === 200) {
				return true
			} else {
				return false
			}
		} catch (error) {
			return false
		}
	}

	const moveToAbandonedCart = async (cartId: string) => {
		const path = "/cart/abandoned-cart"
		try {
			const response = await apiCart.post(path, { cartId })
			if (response.status === 200) {
				return true
			}
		} catch (error) {
			return false
		}
	}

	const updateProductCart = async (dataProduct: any) => {
		try {
			const body = dataProduct
			const path = `/cart/changequantityitem`
			const response: any = await apiCart.put(path, body)
			if (response.status === 200) {
				getCart() // No blocking
				return true
			}
		} catch (error) {
			return false
		}
		return false
	}

	const deleteProductCart = async (dataProduct: DataProductDeleteI) => {
		const body = dataProduct
		const path = `/cart/deleteitem`
		const response: any = await apiCart.delete(path, { data: body })
		if (response.status === 200) {
			await getCart()
		}
	}

	const makeOrder = async (cartId: string) => {
		const body = { cartId }
		const path = `/order/create`
		const response: any = await apiCart.post(path, body)
		if (response.status >= 200) {
			return response.data
		}
	}

	const orderToCartAgain = async (cartId: string) => {
		const body = { cartId }
		const path = "/cart/buy-cart-again-confirm"
		const response = await apiCart.post(path, body)

		if (response.status === 200) {
			return response.data
		}
		throw Error()
	}

	const checkCartNews = async () => {
		if (outOfStock.value.length || productsNews.value.length) {
			return true
		}
		return false
	}

	const resetStateLocalCart = () => {
		cart.value = null
		productsCountCart.value = 0
		pendingNews.value = []
		outOfStock.value = []
		productsNews.value = []
	}

	const organizeCart = () => {
		if (cart.value.products.length) {
			const { cartOrders } = useCartOrders(cart.value.products)
			cart_orders.value = cartOrders.value
		}
	}

    return {
        addProductCart,
        getCart,
        updateCart,
        deleteCart,
        clearCart,
        updateProductCart,
        deleteProductCart,
        makeOrder,
        orderToCartAgain,
        checkCartNews,
        outOfStock,
        productsNews,
        moveToAbandonedCart,
        resetStateLocalCart,
        organizeCart,
        cart_orders,
        orderSelected,
		allProducts,
    };
}
