import { AxiosRequestConfig } from "axios";
import { InterceptorRequestStrategy } from "@/types";
import store from "../../store/index";

/**
 * Si el token es de un Seller, entonces configura el token, el warehouse y los datos del cliente seleccionado,
 * sino solo configura el token y el warehouse.
 */
export class ConfigureCustomerDataSelectedBySeller implements InterceptorRequestStrategy {
    applyInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
        const token: string = store.getters["auth/getToken"];
        const warehouseId = store.getters["auth/getUser"].warehouseId;
        const tradeTypeId = store.getters["auth/getUser"].customer.trade_type;

        const customerConfiguredBySeller = store.getters["auth/getCustomerConfiguredBySeller"];

        let userId = null
        let userCityId = null
        let userStateId = null
        let userTradeTypeId = null

        if ( customerConfiguredBySeller ) {
            userId = customerConfiguredBySeller._id
            userCityId = customerConfiguredBySeller.cityId
            userStateId = customerConfiguredBySeller.stateId
            userTradeTypeId = null
        }

        config.headers = { token };
        config.params = {
            ...config.params,
            warehouse: warehouseId,
            tradeTypeId,
            userId,
            userCityId,
            userStateId,
            userTradeTypeId,
        };

        return config;
    }
}