
import { defineComponent } from "vue"
import SbMainSearch from "@/components/SbMainSearch.vue"
import { useRouter } from "vue-router"

export default defineComponent({
	name: "SbMainSearchWeb",
	components: {
		SbMainSearch,
	},
	setup() {
		const router = useRouter()

		const handlerSearching = () => {
			router.push({
				name: "ViewResultsSearch",
			})
		}

		const handlerClear = () => {
			router.go(-1)
		}

		return {
			handlerClear,
			handlerSearching
		}
	},
})
