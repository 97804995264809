
import { defineComponent, onMounted, ref } from "vue"
import {
	IonApp,
	IonRouterOutlet,
	IonPage,
	IonHeader,
	IonContent,
} from "@ionic/vue"
import { App, URLOpenListenerEvent } from "@capacitor/app"
import { Preferences } from "@capacitor/preferences"
import { SplashScreen } from "@capacitor/splash-screen"
import { useRouter, useRoute } from "vue-router"
import SbOnBoarding from "@/components/SbOnBoarding.vue"
import { useProduct } from "./services/useProduct"
import { useCheckApp } from "./services/useCheckApp"
import { isPlatform } from "@ionic/vue"
import SbNavigationBar from "./components/headers/SbNavigationBar.vue"
import { useMarketing } from "./services/useMarketing"
import SbWelcomePopup from "./components/market_components/SbWelcomePopup.vue"

export default defineComponent({
	name: "App",
	components: {
		IonApp,
		IonRouterOutlet,
		IonPage,
		IonHeader,
		IonContent,
		SbOnBoarding,
		SbNavigationBar,
		SbWelcomePopup,
	},
	setup() {
		const { getProductsFavorite } = useProduct()
		const router = useRouter()
		const route = useRoute()
		const { checkVersion } = useCheckApp()
		const { getPromotionalHeader } = useMarketing()
		const showPopup = ref(false)

		const onBoardingShow = ref(false)

		const deepLinksInit = () => {
			// Deep Links
			App.addListener(
				"appUrlOpen",
				function (event: URLOpenListenerEvent) {
					// Example url: https://www.app.lezag.com/[pathname]
					const url = new (window as any).URLPattern(event.url)
					// path = /home/market
					const path = url.pathname
					// We only push to the route if there is a path present
					if (path) {
						router.push({
							path: path,
						})
					} else {
						router.push({
							name: "MarketplaceHome",
						})
					}
				}
			)
		}

		const setOnBoardingShow = async () => {
			if (!isPlatform("desktop") && route.name === "Login") {
				const userLoginPreviously: any = await Preferences.get({
					key: "userLoginPreviously",
				})

				if (userLoginPreviously.value === null) {
					onBoardingShow.value = true
				} else {
					const isUserLoginPreviously = JSON.parse(
						userLoginPreviously.value
					)
					onBoardingShow.value = !isUserLoginPreviously
				}
			} else {
				onBoardingShow.value = false
			}
		}

		const handlerOnBoardingClose = () => {
			onBoardingShow.value = false
		}

		onMounted(async () => {
			if (!isPlatform("desktop")) await checkVersion()

			deepLinksInit()
			getProductsFavorite()

			// ocultar splash screen
			await SplashScreen.hide()
			await setOnBoardingShow()
			await getPromotionalHeader()
			if (route.name === "MarketplaceHome") {
				showPopup.value = true
			}
		})

		return {
			onBoardingShow,
			handlerOnBoardingClose,
			showPopup,
		}
	},
})
