import { Preferences } from "@capacitor/preferences"
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
import utils from "@/utils/index"
import { useAuth } from "@/services/useAuth"
const { getUser } = useAuth()

const state: any = () => ({
	user: null,
	isLogged: false,
	token: null,
	refresh_token: null,
	data_verify_phone: {}, // data verification to login
	data_login_phone: {}, // data verification to register
	isLoggingIn: true, // Sign In = true, Sign Up = false,
	customerConfiguredBySeller: null,
	isThereCoverage: null,
})

const getters = {
	getIsLogged(state: any) {
		return state.isLogged
	},
	getToken(state: any) {
		return state.token
	},
	getAliveToken(state: any) {
		return utils.tokenAlive(state.token)
	},
	getDataVerifyPhone(state: any) {
		return state.data_verify_phone
	},
	getDataLoginPhone(state: any) {
		return state.data_login_phone
	},
	getUserId(state: any) {
		if (state.user) {
			return state.user._id
		}
		return null
	},
	getCustomerConfiguredBySeller(state: any) {
		if (state.customerConfiguredBySeller) {
			return state.customerConfiguredBySeller
		}
		return null
	},
	getUser(state: any) {
		return state.user
	},
	getRole(state: any) {
		return state.user?.rol
	},
}

const actions = {
	async getIsLoggingIn() {
		const response: any = await Preferences.get({ key: "isLoggingIn" })
		return JSON.parse(response.value)
	},
	async recoverTokenFromStorage({ commit }: any) {
		const response: any = await Preferences.get({ key: "token" })
		commit("setToken", response.value)
	},
	async saveDataLogin({ commit, dispatch }: any, data: any) {
		// Save data user in store
		const token = data.tokenReturn
		const refreshToken = data.refreshToken
		commit("setToken", token)
		commit("setRefreshToken", refreshToken)

		// Set data user in localStorage
		Preferences.set({ key: "token", value: token })
		cookies.set(
			"refreshToken",
			refreshToken,
			new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
		)
		await dispatch("getDataUserLoggedIn")
	},
	async getDataUserLoggedIn({ commit }: any) {
		const data: any = await getUser()
		const dataUser = {
			...data.user,
			warehouseId: data.warehouse,
			schedule: data.schedule,
			ziro_credit: data.ziro_credit,
			customer: data.customer,
		}
		commit("setUser", dataUser)
	},
	async userLogout({ commit, dispatch }: any) {
		commit("clearUserData")
		await dispatch("clearLocalStorage")
	},
	async clearLocalStorage() {
		const userLoginPreviously: any = await Preferences.get({
			key: "userLoginPreviously",
		})
		await Preferences.clear()

		await Preferences.set({
			key: "userLoginPreviously",
			value: userLoginPreviously.value,
		})

		cookies.remove("refreshToken")
	},
}

const mutations = {
	setIsLoggingIn(state: any, payload: boolean) {
		Preferences.set({
			key: "isLoggingIn",
			value: JSON.stringify(payload),
		})
		state.isLoggingIn = payload
	},
	setDataVerifyPhone(state: any, payload: any) {
		Preferences.set({
			key: "dataVerifyPhone",
			value: JSON.stringify(payload),
		})
		state.data_verify_phone = payload
	},
	setDataLoginPhone(state: any, payload: any) {
		Preferences.set({
			key: "dataLoginPhone",
			value: JSON.stringify(payload),
		})
		state.data_login_phone = payload
	},
	setToken(state: any, token: any) {
		state.token = token
		// state.user = jwt_decode(token);
		state.isLogged = true
	},
	setUser(state: any, user: any) {
		state.user = user
	},
	setRefreshToken(state: any, refreshToken: any) {
		state.refresh_token = refreshToken
	},
	clearUserData(state: any) {
		state.token = null
		state.refresh_token = null
		state.user = null
		state.isLogged = false
		state.data_login_phone = {}
		state.data_verify_phone = {}
	},
	setCustomerConfiguredBySeller(state: any, payload: any) {
		state.customerConfiguredBySeller = payload
	},
	setCoverage(state: any, payload: boolean) {
		state.isThereCoverage = payload
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
