
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import SbIcon from "@/components/icons/SbIcon.vue"

import { useProduct } from "@/services/useProduct"
import { useSearch, searchKey, query, limitResult } from '@/composables/useSearch'
import { useTerm } from '@/services/useTerm'

export default defineComponent({
	name: "SbMainSearch",
	components: { SbIcon },
	props: {
		placeholder: {
			type: String,
			default: "",
		},
		name: {
			type: String,
			default: "",
		},
		shadow: {
			type: Boolean,
			default: true,
		},
		focus: {
			type: Boolean,
			default: false,
		},
	},
	setup(props: any, { emit }) {
		const { getProducts, products } = useProduct()
		const { isSearching, searchResults, recentSearchesList, handlerCleanSearch } = useSearch()
		const { saveTerm } = useTerm()
		const haveButtonClear = ref(false)
		const inputRef = ref()
		
		const debounceTime = 500;
		let debounceTimer: any;

		watch(searchKey, async (searchKeyNewValue) => {
			clearTimeout(debounceTimer);
			isSearching.value = true
			
			debounceTimer = setTimeout(async () => {
				if (searchKeyNewValue) {
					haveButtonClear.value = true
					query.value = `?q=${searchKeyNewValue}&limit=${limitResult.value}`
					const recentSearch = recentSearchesList.value.find((search: any) => search.query === query.value)

					if (!recentSearch) {
						await getProducts(query.value)

						const results = products.value

						recentSearchesList.value.push({
							query: query.value,
							results,
						})

						searchResults.value = results
						// Save search term
						saveTerm(searchKeyNewValue)
					} else {
						searchResults.value = recentSearch.results
					}

				} else {
					searchResults.value = []
					haveButtonClear.value = false
				}

				isSearching.value = false
			}, debounceTime)
		})

		onBeforeUnmount(() => {
			clearTimeout(debounceTimer);
		});

		watch(
			() => props.focus,
			() => {
				setTimeout(() => {
					inputRef.value.focus()
				}, 500)
			}
		)

		const updateSearch = (event: any) => {
			searchKey.value = event.target.value

			if ( searchKey.value ) {
				emit('onSearching')
			}
		}

		onMounted(() => {
			emit("searchInstance", inputRef.value)
		})

		const onEnter = () => {
			if (searchKey.value) {
				emit("onEnter", searchKey.value)
			}
		}

		const onClear = () => {
			handlerCleanSearch()
			emit("onClear")
		}

		const handlerBlur = () => {
			emit("onBlur")
		}

		return {
			inputRef,
			searchKey,
			haveButtonClear,
			onEnter,
			onClear,
			handlerBlur,
			updateSearch,
		}
	},
})
